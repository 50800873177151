<template>
    <BorderBox :bg="true">
        <div class="base-infos">
            <div class="title">区域基本信息</div>
            <div class="content-view">
               <div class="item">
                   <div class="name">区域名称</div>
                   <div class="val">{{areaInfo.area_name}}</div>
               </div>
               <div class="item">
                   <div class="name">区域地址</div>
                   <div class="val">{{areaInfo.area_region_address}}</div>
               </div>
               <div class="item">
                   <div class="name">区域简介</div>
                   <div class="val">{{areaInfo.area_introl}}</div>
               </div>
            </div>
        </div>
    </BorderBox>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';

export default {
    name: 'BaseInfos',
    components: { BorderBox },
    props: ['areaInfo'],
}
</script>

<style lang="less">
.base-infos{
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .title{
        font-size: 14px;
        font-family: 'AliHYAiHei';
        font-weight: 400;
        color: #FFFFFF;
    }
    .content-view{
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        .item{
            margin-bottom: 8px;
            .name{
                font-size: 14px;
                font-family: 'Alibaba-PuHuiTi-Regular';
                font-weight: 400;
                color: rgba(255, 255, 255, 0.6);
                padding-bottom: 2px;
            }
            .val{
                font-size: 14px;
                font-family: 'Alibaba-PuHuiTi-Regular';
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
}
</style>