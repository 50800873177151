<template>
    <div class="info-views">
        <div class="base-infos-box">
            <!-- 区域基本信息 --> 
            <BaseInfos :areaInfo="areaInfo" />
        </div>
        <div class="shops-nunbers-box">
            <!-- 企业数量 -->
            <ShopsNunbers :areaInfo="areaInfo" />
        </div>
    </div>
</template>

<script>

import BaseInfos from './BaseInfos.vue';
import ShopsNunbers from './ShopsNunbers.vue';

export default {
    name: 'InfoViews',
    props: ['areaInfo'],
    components: { BaseInfos, ShopsNunbers },
}
</script>

<style lang="less">
.info-views{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .base-infos-box{
        min-height: 230px;
    }
    .shops-nunbers-box{
        height: 90px;
        margin: 20px 0;
    }
}
</style>