<template>
    <div class="are-information-view">
        <div class="ledger-shopsearch">
            <!-- <LedgerList title="网格机构" :list="ledgerList" @onClick="onLedgerClick" :active="ledgerActive"/> -->
            <ShopSearch title="企业列表" :list="shopList" @onSearch="onShopSearch" @onClick="onShopClick" :active="shopActive"/>
        </div>
         <div :class="{'table-charts-view': true, charts: !ledgerActive}">
            <div class="table-modal" v-if="ledgerActive">
                <GridMechanism title="组织架构" @onClick="onTableClose" />
            </div>
            <div class="charts-modal" v-if="!ledgerActive">
                <InfoView :areaInfo="areaInfo" />
            </div>
        </div>
    </div>
</template>

<script>

import EventBus from '@/commons/event-bus.js'

import LedgerList from '@/components/LedgerList.vue';
import ShopSearch from '@/components/ShopSearch.vue';

import InfoView from './info-view/Index.vue'
import GridMechanism from './grid-mechanism/Index.vue'


export default {
    name: 'AreInformation',
    components: { LedgerList, ShopSearch, InfoView, GridMechanism },
    data(){
        return {
            ledgerActive: null,
            shopActive: null,
            ledgerList: [
               { key: '1', name: '组织架构' }
            ],
            enterpriseList: [], //左侧企业列表 可搜索
            shopList: [], // 左侧企业搜索结果
            areaInfo: [], // 区域信息基础信息
        }
    },
    mounted(){
        //页面初始化接口
        this.initData();
    },
    methods:{
        //页面初始化
        initData(){
            this.getEnterprises();
            this.getAreaInfo();
        },

        //获得企业信息列表
        async getEnterprises(){
            //04-基础-获得下属企业信息列表
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getEnterpriseBaseList');
            if(!err && res){
                 this.enterpriseList = res.data.enterprise_list || [];
                this.setMapData();
            }
        },

        // 设置map的marker信息
        setMapData(){
            const markers = this.enterpriseList.map(item => {
               const {  
                    enterprise_id,
                    enterprise_name,
                    grid_info:{
                        longitude: long, // 经度
                        latitude: lat    // 维度
                    },
                    enterprise_legal_person: person, // 企业法人
                    enterprise_phone: phone,        // 联系方式
                    enterprise_address: address,  // 详细地址
                    enterprise_class_info: {
                        enterprise_class_name: class_info, //企业类型名称
                    },
                    three_simultaneous_info: {
                        three_simultaneous_name: three, //三同完成情况		
                    },	
                    major_hazard_sources_info: {
                        major_hazard_sources_name: hazard, //重大危险源名称		
                    },
                    enterprise_level_info: {
                        enterprise_level_name: level  //企业风险等级名称
                    }	
                   
               } = item;
               return {
                    enterprise_id,
                    enterprise_name,
                    long,
                    lat,
                    level_name: '区域信息',
                    type: '区域信息',
                    info: { person, phone, address, class_info, three, hazard, level } 
               }
           })

           EventBus.$emit("changeMarkers", markers);
        },

        //获得获得区域信息
        async getAreaInfo(){
            //03-区域信息-获得区域信息
            let [res, err] = await this.$Fx('/Api_Area/Area/Area/getAreaInfo');
            if(!err && res){
                 this.areaInfo = res.data.area_info || {};
            }
        },

        //点击隐患台账清单
        onLedgerClick(item){
            const isActive = this.ledgerActive && item.key === this.ledgerActive.key;
            this.ledgerActive =  isActive ? null : item;
        },

        //点击表格关闭按钮
        onTableClose(flag){
            this.ledgerActive = flag;
        },

        //点击企业列表中的某个企业
        onShopClick(item){
            const isActive = this.shopActive && item.enterprise_id === this.shopActive.enterprise_id;
            this.shopActive =  isActive ? null : item;

            const { grid_info: { latitude: lat, longitude: long } } = item;
            EventBus.$emit("moveCenter", { lat, long } );
        },

        //搜索企业列表
        onShopSearch(value){
           this.shopList = value ? this.enterpriseList.filter( item=> item.enterprise_name.includes(value) ) : [];
        },
    } 
}
</script>

<style lang="less">
.are-information-view{
    height: 100%;
    display: flex;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    .ledger-shopsearch{
        display: flex;
        flex-direction: column;
        padding: 60px 0 0 0px;
    }
    .table-charts-view{
        flex: 1 1 auto;
        padding-left: 15px;
        &.charts{
            display: flex;
            justify-content: flex-end;
        }
        .table-modal{
            width: 100%;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
        .charts-modal{
            width: 325px;
            height: 100%;
            border-radius: 5px;
            pointer-events: all;
        }
    }
    .footer-cricle-view{
        // border: 1px solid #aaa;
        position: absolute;
        bottom: 20px;
        left: 20px;
        pointer-events: all;
        display: flex;
    }
}
</style>