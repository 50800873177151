<template>
    <BorderBox :bg="true">
        <div class="grid-mechanism-view">
            <Title :text="title" class="title-close">
                <div class="close" @click="closeTable"> <i class="el-icon-close"></i> </div> 
            </Title>    
            <div class="grid-mechanism">
                组织架构图
            </div>
        </div>
    </BorderBox>
</template>

<script>

import Title from '@/components/Title.vue';
import BorderBox from '@/components/BorderBox.vue';

export default {
    name: 'table-list',
    components: { Title, BorderBox },
    props: ['title', 'onClick'],
    data(){
        return {
            data: []
        }
    },
    mounted() {
    },
    methods: {
        //关闭表格事件
        closeTable(){
            this.$emit('onClick', null);
        }
    },
}
</script>

<style lang="less">
.grid-mechanism-view{
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
    .grid-mechanism{
        flex: 1 1 auto;
    }
}

.title-close{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: 40px;
    padding-bottom: 15px;
    padding-left: 10px;
    .close{
        width: 20px;
        height: 20px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        &:hover{
            transform: scale(1.2);
            color: #7DEBFF;
        }
    }
}

</style>