<template>
    <div class="mode-name-title" :class="className">
        <div>{{text}}</div>
        <slot></slot>
    </div>
</template>
<script>


export default {
    name: 'ModeNameTitle',
    props: ['text', 'className'],
}
</script>

<style lang="less">
.mode-name-title{
    width: 100%;
    font-size: 14px;
    font-family: 'AliHYAiHei';
    font-weight: 400;
    color: #FFFFFF;
    display: flex;
}
</style>