<template>
    <BorderBox :bg="true">
        <div class="shops-nunbers">
            <div class="title">企业数量</div>
            <div class="content-view">
                <div class="text">区域内企业数量</div>
                <div class="value">
                    <div class="num">{{areaInfo.area_enterprise_count}}</div>
                    <div class="unit">家</div>
                </div>
            </div>
        </div>
    </BorderBox>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';


export default {
    name: 'ShopsNunbers',
    props: ['areaInfo'],
    components: { BorderBox },
}
</script>

<style lang="less">
.shops-nunbers{
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .title{
        font-size: 14px;
        font-family: 'AliHYAiHei';
        font-weight: 400;
        color: #FFFFFF;
    }
    .content-view{
        flex: 1 1 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text{
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
            font-family: 'Alibaba-PuHuiTi-Regular';
            font-weight: 400;
        }
        .value{
            display: flex;
            .num{
                font-size: 22px;
                font-family: 'AliHYAiHei';
                font-weight: 400;
                color: #16DBFF;
                line-height: 17px;
            }
            .unit{
                padding-left: 3px;
                font-size: 14px;
                font-family: 'Alibaba-PuHuiTi-Regular';
                font-weight: 400;
                color: #16DBFF;
                line-height: 17px;
                position: relative;
                top: 4px;
            }
        }
    }
}

</style>