<template>
    <div class="ledger-list-box">
        <BorderBox :bg="true">
            <div class="ledger-list">
                <Title :text="title" />
                <div class="list">
                    <div v-for="item in list" :key="item.key" :class="{item, active: active && item.key === active.key}" @click="selectList(item)">
                        {{item.name}}
                    </div>
                </div>
            </div>
        </BorderBox>
    </div>
</template>

<script>

import BorderBox from '@/components/BorderBox.vue';
import Title from '@/components/Title.vue';

export default {
    name: 'LedgerList',
    props:['title', 'list', 'onClick', 'active'],
    components: { BorderBox, Title },
    methods: {
        selectList(item){
            this.$emit('onClick', item);
        }
    }
}
</script>

<style lang="less" scope>
.ledger-list-box{
    width: 300px;
    min-height: 98px;
    .ledger-list{
        pointer-events: all;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
    }
}

.ledger-list-box{
    width: 300px;
    min-height: 98px;
    .title{
        font-size: 14px;
        font-family: 'AliHYAiHei';
        font-weight: 400;
        color: #FFFFFF;
    }
}

.ledger-list-box{
    .list{
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        .item{
            height: 30px;
            background: rgba(76, 156, 255, 0.2);
            border: 1px solid #3C79B1;
            border-radius: 5px;
            color: #fff;
            font-size: 15px;
            padding: 0 0 0 15px;
            cursor: pointer;
            position: relative;
            display: flex;
            align-items: center;
            margin: 5px 0;
            &:hover{
                background: rgba(125, 235, 255, 0.2);
            }
            &::before{
                content: '';
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-bottom: 5px solid #3C79B1;
                position: absolute;
                top: 5px;
                left: 5px;
                transform: rotate(-45deg);
            }
            &.active{
                border: 1px solid #7DEBFF;
                background: rgba(125, 235, 255, 0.2);
                &::before{
                    border-bottom: 5px solid #7DEBFF;
                }
            }
        }
    }
}

</style>